import React, { useState } from "react"
import styled from "styled-components"
import { graphql, Link as GatsbyLink } from "gatsby"
import { useTranslation } from "react-i18next"
import { containerSmallMaxWidth, MEDIA_MIN_MEDIUM } from "../constants"
import Layout from "../components/Layout"
import SecureLayout from "../components/SecureLayout"
import Button from "../components/ui/Button"
import SectionButtons from "../components/faq/SectionButtons"
import SectionArticles from "../components/faq/SectionArticles"
import Breadcrumbs from "../components/ui/Breadcrumbs"

const Container = styled.div`
  max-width: ${containerSmallMaxWidth};
  margin: 0 auto;
  padding: 2.5rem;
`

const Title = styled.h1`
  margin-top: 3rem;
  padding: 0 3rem 3rem;
  text-align: center;
  font-size: 2.4rem;
  line-height: 3rem;

  ${MEDIA_MIN_MEDIUM} {
    font-size: 3.2rem;
  }
`

const Contact = styled.div`
  margin: 0 auto;
  padding: 2rem 0 6rem;
  text-align: center;

  ${MEDIA_MIN_MEDIUM} {
    padding: 2rem 0 8rem;
  }

  p {
    margin-top: 0.5rem;
  }
`

const ContactButton = styled(Button)`
  margin: 0 auto;
  background: ${(p) => p.theme.colors.primary};
  border: 0.1rem solid ${(p) => p.theme.colors.primary};
  color: ${(p) => p.theme.colors.white};
  width: auto;
`

const FaqPage = ({ data: { sanityFaqPage }, pageContext }) => {
  const { t } = useTranslation("translations")
  const [selectedSection, setSelectedSection] = useState("faq")
  const loginEnabled = process.env.GATSBY_VIP_ENABLE_LOGIN === "true"
  const { title, tabItems } = sanityFaqPage
  const sections = [...new Set(tabItems.map((item) => item.category))]
  const LayoutComponent = loginEnabled ? SecureLayout : Layout

  return (
    <LayoutComponent
      meta={{ title: title.en }}
      invertedHeader
      pageContext={pageContext}
    >
      <Breadcrumbs
        breadcrumbs={[
          {
            slug: "/faq/",
            name: title.en
          }
        ]}
      />
      <Container>
        <Title>{title.en}</Title>
        <SectionButtons
          sections={sections}
          selectedSection={selectedSection}
          setSelectedSection={setSelectedSection}
        />
        <SectionArticles
          articles={tabItems}
          selectedSection={selectedSection}
        />
        <Contact>
          <strong>{t("Can't find your question?")}</strong>
          <p>{t("Get in touch with us and we will help you!")}</p>
          <GatsbyLink to="/get-in-touch/">
            <ContactButton hasIcon>
              {t("Contact us")} <i className="fal fa-paper-plane"></i>
            </ContactButton>
          </GatsbyLink>
        </Contact>
      </Container>
    </LayoutComponent>
  )
}

export default FaqPage

export const query = graphql`
  query {
    sanityFaqPage {
      title {
        en
      }
      tabItems {
        title {
          en
        }
        _rawText(resolveReferences: { maxDepth: 10 })
        category
        updatedAt: _updatedAt
      }
    }
  }
`
